import React from 'react'
import { graphql } from 'gatsby'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import Hr from '../components/Hr/Hr'
import * as BlocksStyles from '../components/Blocks/Blocks.module.scss'
import * as ArticleStyles from '../components/layoutComponents/article/Article.module.scss'
import PageMeta from '../components/PageMeta/PageMeta'
import { type InstagramPageData } from '../graphql/queries/InstagramPageQuery'

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      page(id: $id) {
        title
        content
        uri
        link
        parent {
          node {
            uri
          }
        }
      }
    }
  }
`

interface InstagramProps {
  data: InstagramPageData
}

const Instagram = ({
  data: {
    wpgraphql: { page }
  }
}: InstagramProps) => {
  return (
    <>
      <PageMeta
        pageData={{
          title: 'Instagram | Muddy Stilettos',
          description: 'Check out the latest Instagram posts from Muddy',
          url: page.link
        }}
      />
      <Header />
      <div className={`${ArticleStyles.Intro} ${ArticleStyles.IntroSpacer}`}>
        <h1>Instagram</h1>
      </div>
      <Hr />
      <div
        className={BlocksStyles.PageBlocks}
        dangerouslySetInnerHTML={{ __html: page.content ?? '' }}
      ></div>
      <Footer />
    </>
  )
}

export default Instagram
